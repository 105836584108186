export interface IThemeDetail {
  color: string
  background: string
}

interface ITheme {
  white: IThemeDetail
  dark: IThemeDetail
  gray: IThemeDetail
}

export const themes: ITheme = {
  white: {
    color: '#000',
    background: '#fff',
  },
  dark: {
    color: '#fff',
    background: '#000',
  },
  gray: {
    color: '#000',
    background: '#E5E5E5',
  },
}