import * as React from 'react'
import styled from 'styled-components'
import { mediaWidth } from '../../../../styles/layout'
import intersectionAnimation, { IAnimationRefProps } from '..'

const AnimationWrapper = styled.div`
  transform: translateY(50px);
  opacity: 0;
  transition-duration: .5s;
  transition-property: opacity, transform;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    transform: translateY(25px);
  }
  &.anim-enter,
  &.anim-enter-done {
    opacity: 1;
    transform: translateY(0);
  }
  &.anim-exit,
  &.anim-exit-done {
    opacity: 0;
    transform: translateY(50px);
    @media screen and (max-width: ${ mediaWidth.sp }px) {
      transform: translateY(50px);
    }
  }
`

interface IFadeInFC extends IAnimationRefProps {
}

const FadeInFC: React.FC<IFadeInFC> = ({ children, animationRef }) => (
  <AnimationWrapper ref={ animationRef }>
    { children }
  </AnimationWrapper>
)

interface IProps {
  delay?: number
}

const customFadeInWrapper = ({ delay = 0 }: IProps) => intersectionAnimation({
  rootMargin: '9999px 0px -20%',
  timeout: 500 + delay,
})(FadeInFC)

export default customFadeInWrapper