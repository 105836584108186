import * as React from 'react'
import styled from 'styled-components'
import { pcSpace, spSpace, zIndex } from '../../../styles/layout'
import { CSSTransition } from 'react-transition-group'
import { mediaWidth } from '../../../styles/layout'
import { fontSize } from '../../../styles/font'

const Overlay = styled.div`
  animation-fill-mode: forwards;
  background-color: #000;
  box-sizing: border-box;
  display: none;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ${ zIndex.overlay };
  @media screen and (min-width: ${ mediaWidth.sp }px) {
    display: flex;
    padding: ${ pcSpace.outline.side }px;
    width: 30vw;
    opacity: 0;
    transform: translateX(100%);
    transition: .25s;
    transition-property: transform, opacity;
    &.anim-enter,
    &.anim-enter-done {
      opacity: 1;
      transform: translateX(0);
    }
    &.anim-exit {
      transform: translateX(100%);
    }
  }
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding: ${ spSpace.outline.side }px;
    opacity: 0;
    transition: .5s;
    transition-property: opacity;
    &.anim-enter,
    &.anim-enter-done,
    &.anim-exit {
      display: flex;
    }
    &.anim-enter-active,
    &.anim-enter-done {
      opacity: 1;
    }
    &.anim-exit-active {
      opacity: 0;
    }
    &.anim-exit-done {
      display: none;
    }
  }
`

interface IOverlay {
  isShow: boolean
}

const LinkList = styled.ul`
  & > li:not(:first-child) {
    margin-top: 30px;
  }
`

interface ILinkItem {
  delay: number
  duration: number
}

const LinkItem = styled.li`
  transform: translateX(200%);
  &.anim-enter {
    transform: translateX(200%);
    transition-property: transform;
    transition-duration: ${ ({ duration }: ILinkItem) => duration }s;
    transition-delay: ${ ({ delay }: ILinkItem) => delay }s;
  }
  &.anim-enter-active,
  &.anim-enter-done,
  &.anim-exit {
    transform: translateX(0);
  }
`

const Links = styled.a`
  color: #fff;
  font-size: ${ fontSize.px36 };
  display: block;
  transition: .5s;
  text-decoration: none;
  &:hover {
    opacity: .5;
  }
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px26 };
  }
`

interface IFadeInItem extends IOverlay {
  index: number
  href: string
  onClick: () => void
}

const FadeInItem: React.FC<IFadeInItem> = ({ isShow, index, children, href, onClick }) => {
  const duration = 0.25
  const baseDelay = 0.25
  const timeout = isShow ? (baseDelay + duration + index / 10) * 1000 : 0
  return (
    <CSSTransition
      in={ isShow }
      timeout={ timeout }
      classNames="anim"
    >
      <LinkItem delay={ baseDelay / 2 + index * 0.05 } duration={ duration }>
        <Links href={ href } onClick={ onClick }>{ children }</Links>
      </LinkItem>
    </CSSTransition>
  )
}

interface IMenuLinks extends IOverlay {
  onClick: () => void
  onMouseLeave: () => void
}

const MenuLinks: React.FC<IMenuLinks> = ({ isShow, onClick, onMouseLeave }) => {
  return (
    <CSSTransition
      in={ isShow }
      timeout={ 500 }
      classNames="anim"
    >
      <Overlay onMouseLeave={ onMouseLeave }>
        <LinkList>
          <FadeInItem onClick={ onClick } href="/#top" isShow={ isShow } index={ 1 }>TOP</FadeInItem>
          <FadeInItem onClick={ onClick } href="/#work" isShow={ isShow } index={ 2 }>WORK</FadeInItem>
          <FadeInItem onClick={ onClick } href="/#identity" isShow={ isShow } index={ 3 }>IDENTITY</FadeInItem>
          <FadeInItem onClick={ onClick } href="/#service" isShow={ isShow } index={ 4 }>SERVICE</FadeInItem>
          <FadeInItem onClick={ onClick } href="/#company" isShow={ isShow } index={ 5 }>COMPANY</FadeInItem>
        </LinkList>
      </Overlay>
    </CSSTransition>
  )
}

export default MenuLinks
