import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import { CSSTransition } from 'react-transition-group'

interface ISetting {
  rootMargin: string
  timeout: number
}

export interface IAnimationRefProps {
  animationRef: () => void
}

type KeyExclude<T, U extends keyof T> = {[K in Exclude<keyof T, U>]: T[K]}

const intersectionAnimation = ({ rootMargin, timeout }: ISetting) =>  <T extends IAnimationRefProps>(Component: React.FC<T>): React.FC<KeyExclude<T, 'animationRef'>> => (props) => {
  // on run dev, false
  const triggerOnce = process.env.NODE_ENV !== 'development'

  const [ref, inView] = useInView({
    rootMargin,
    threshold: 0,
    triggerOnce,
  })

  // @see https://github.com/microsoft/TypeScript/issues/28938
  const innerProps = {
    ...props,
    animationRef: ref,
  } as T

  return (
    <CSSTransition
      in={ inView }
      timeout={ timeout }
      classNames="anim"
    >
      <Component { ...innerProps } />
    </CSSTransition>
  )
}

export default intersectionAnimation