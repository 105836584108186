import * as React from 'react'
import Hamburger from '../../atoms/Hamburger'
import MenuLinks from '../../molecules/MenuLinks'
import { mediaWidth } from '../../../styles/layout'

const Header: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false)
  const toggleOpen = () => setOpen(!isOpen)
  const setForceOpen = (value: boolean) => setOpen(value)

  const onMouseEnter = () => {
    if (window.innerWidth > mediaWidth.sp) {
      setForceOpen(true)
    }
  }

  const onMouseLeave = () => {
    if (window.innerWidth > mediaWidth.sp) {
      setForceOpen(false)
    }
  }

  return (
    <>
      <MenuLinks isShow={ isOpen } onClick={ toggleOpen } onMouseLeave={ onMouseLeave } />
      <Hamburger open={ isOpen } onClick={ toggleOpen } onMouseEnter={ onMouseEnter } />
    </>
  )
}

export default Header
