import * as React from 'react'
import { GlobalStyles } from '../../../styles/global'
import styled from 'styled-components'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import { IThemeDetail, themes } from '../../../styles/theme'
import { mediaWidth, spSpace } from '../../../styles/layout'
import customFadeInWrapper from '../../hoc/intersectionAnimation/customFadeInWrapper'
import { IContentfulWorkCase } from '../../../templates/case'
import { fontSize } from '../../../styles/font'
import { Link } from 'gatsby'

const Contents = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
`

interface IWrapper {
  theme: IThemeDetail
}

const Wrapper = styled.div`
  background-color: ${ ({ theme }: IWrapper) => theme.background };
  display: flex;
  justify-content: center;
`

const Inner = styled.div`
  box-sizing: border-box;
  padding: 100px 0;
  width: 85vw;
  max-width: 1160px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding: 50px ${ spSpace.outline.side }px;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    display: block;
    padding-top: 45%;
  }
`

const SubImageWrapper = styled(ImageWrapper)`
  &::before {
    padding-top: calc(100%/3*2);
  }
`

const Image = styled.img`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const Detail = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 50px;
  margin-top: 50px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    display: block;
  }
`

const Title = styled.h1`
  color: #333;
  font-size: ${ fontSize.px36 };
  font-weight: bold;
  line-height: 1;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px26 };
  }
`

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  line-height: 1.5;
  font-size: ${ fontSize.px16 };
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px12 };
  }
`

const SplitSlice = styled.span`
  padding: 0 15px;
`

const TagLink = styled(Link)`
  color: #333;
  position: relative;
  transition: color .5s;
  text-decoration: none;
  &:after {
    background-color: #333;
    content: '';
    display: block;
    height: 1px;
    width: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: width .5s;
  }
  &:hover {
    color: #333;
    &:after {
      width: 100%;
    }
  }
`

const Description = styled.p`
  color: #666;
  font-size: ${ fontSize.px16 };
  line-height: 1.5;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px12 };
    margin-top: 10px;
  }

`

const SubWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  margin: 100px auto 0;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    margin: 50px auto 0;
    grid-gap: 30px;
  }
`

const SubText = styled.p`
  color: #666;
  font-size: ${ fontSize.px16 };
  line-height: 1.5;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px12 };
  }
`

const FadeInWrapper = customFadeInWrapper({})

interface IProps {
  work: IContentfulWorkCase
}

const WorkCaseLayout: React.FC<IProps> = ({ work }) => {
  const srcMainOriginal = work.main.sizes.src.replace(/\?.*$/, '')
  return (
    <>
      <GlobalStyles />
      <Header />
      <Contents>
        <Wrapper theme={ themes.white } id="work">
          <Inner>
            <FadeInWrapper>
              <ImageWrapper>
                <Image src={ srcMainOriginal } alt="" />
              </ImageWrapper>
              <Detail>
                <div>
                  <Title>{ work.name }</Title>
                  <TagWrapper>
                    <span>
                      <TagLink to={ `/work/` }>ALL</TagLink>
                    </span>
                    { work.category.map((category, i) => (
                      <span key={ i }>
                        <SplitSlice>/</SplitSlice>
                        <TagLink to={ `/work/?category=${ category.name }` }>{ category.name }</TagLink>
                      </span>
                    )) }
                  </TagWrapper>
                </div>
                { work.description && <Description>{ work.description }</Description> }
              </Detail>
            </FadeInWrapper>
            <SubWrapper>
              { work.subset.map((sub, i) => {
                const srcSubOriginal = sub.sizes.src.replace(/\?.*$/, '')
                return (
                  <FadeInWrapper>
                    <SubImageWrapper key={ i }>
                      <Image src={ srcSubOriginal } alt="" />
                    </SubImageWrapper>
                  </FadeInWrapper>
                )
              }) }
              <FadeInWrapper>
                { work.subText && work.subText.subText && <SubText>{ work.subText.subText }</SubText> }
              </FadeInWrapper>
            </SubWrapper>
          </Inner>
        </Wrapper>
      </Contents>
      <Footer />
    </>
  )
}

export default WorkCaseLayout
