import * as React from 'react'
import styled from 'styled-components'
import { mediaWidth } from '../../../styles/layout'
import { fontSize } from '../../../styles/font'

const Wrapper = styled.footer`
  background-color: #000;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`

const Copyright = styled.p`
  color: #aaa;
  font-size: ${ fontSize.px12 };
  font-family: FB PoynterSerif RE Regular,A1 Mincho,ヒラギノ明朝 ProN W3,HiraMinProN-W3,ＭＳ\\ Ｐ明朝,serif;
  line-height: 1;
  max-width: 944px;
  padding: 0 20px;
  width: 100%;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px10 };
    line-height: 1.5;
  }
`

const date = new Date()
const year = date.getFullYear()

const Footer = () => {
  return (
    <Wrapper>
      <Copyright>Copyright © { year } FULL DESIGN inc. All rights reserved.</Copyright>
    </Wrapper>
  )
}

export default Footer
