import * as React from 'react'
import { graphql } from 'gatsby'
import WorkCaseLayout from '../components/templates/WorkCaseLayout'

export interface IContentfulWorkCase {
  name: string,
  description: string,
  contentful_id: string,
  main: {
    sizes: {
      src: string
    }
  },
  subset: [{
    sizes: {
      src: string
    }
  }]
  category: [{
    name: string
  }]
  subText: {
    subText: string
  }
}

interface IProps {
  data: {
    contentfulWork: IContentfulWorkCase
  }
}

const IndexPage: React.FC<IProps> = ({ data: { contentfulWork } }) => (
  <WorkCaseLayout work={ contentfulWork } />
)

export default IndexPage

export const pageQuery = graphql`
  query CasePageQuery($contentfulId: String!) {
    contentfulWork(contentful_id: {eq: $contentfulId}) {
      name
      description
      contentful_id
      main {
        sizes {
          src
        }
      }
      subset {
        sizes {
          src
        }
      }
      category {
        name
      }
      subText {
        subText
      }
    }
  }
`
