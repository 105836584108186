interface IFontSize {
  px10: string
  px12: string
  px14: string
  px16: string
  px20: string
  px26: string
  px36: string
  px50: string
}

export const fontSize: IFontSize = {
  px10: '10px',
  px12: '12px',
  px14: '14px',
  px16: '16px',
  px20: '20px',
  px26: '26px',
  px36: '36px',
  px50: '50px',
}
