import * as React from 'react'
import styled, { css } from 'styled-components'
import { mediaWidth, pcSpace, spSpace, zIndex } from '../../../styles/layout'

const pcViewHeight = 35
const spViewHeight = 25

const Wrapper = styled.button`
  box-sizing: content-box;
  display: block;
  position: fixed;
  top: ${ pcSpace.outline.side }px;
  right: ${ pcSpace.outline.side }px;
  z-index: ${ zIndex.overlayContained };
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    top: ${ spSpace.outline.side }px;
    right: ${ spSpace.outline.side }px;
  }
`

interface IThreeLine {
  open: boolean;
}

const ThreeLine = styled.div`
  height: ${ pcViewHeight }px;
  position: relative;
  width: ${ pcViewHeight * 1.2 }px;
  transition: .25s;
  ${ ({ open }: IThreeLine) => open ? css`
      transform: rotate(90deg);
    ` : css`
    ` }
    }
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    height: ${ spViewHeight }px;
    width: ${ spViewHeight * 1.2 }px;
  }
  & > span {
    background-color: #333;
    content: '';
    display: block;
    height: 4px;
    width: ${ pcViewHeight * 1.2 }px;
    position: absolute;
    top: 0;
    &:nth-child(2){
      top: calc(50% - 2px);
    }
    &:nth-child(3){
      top: calc(100% - 4px);
    }
    @media screen and (max-width: ${ mediaWidth.sp }px) {
      height: 2px;
      width: ${ spViewHeight * 1.2 }px;
      &:nth-child(2){
        top: calc(50% - 1px);
      }
      &:nth-child(3){
        top: calc(100% - 2px);
      }
    }
    ${ ({ open }: IThreeLine) => open ? css`
      transition: .25s;
      &:first-child {
        background-color: #fff;
        top: 50%;
        transform: rotate(-45deg);
      }
      &:nth-child(2) {
        background-color: transparent!important;
      }
      &:nth-child(3) {
        background-color: #fff;
        top: 50%;
        transform: rotate(45deg);
      }
    ` : css`
      transition: .25s;
    ` }
    }
`

interface IButton {
  open?: boolean
  onClick: () => void
  onMouseEnter: () => void
}

const Hamburger: React.FC<IButton> = ({ open = false, onClick, onMouseEnter }) => (
  <Wrapper onClick={ onClick } onMouseEnter={ onMouseEnter }>
    <ThreeLine open={ open }>
      <span />
      <span />
      <span />
    </ThreeLine>
  </Wrapper>
)

export default Hamburger
